document.addEventListener('DOMContentLoaded', (event) => {
  const form = document.querySelector('.search-form-container form');
  const textField = form.querySelector('input[name="search[keyword]"]');
  const submitButton = form.querySelector('input[type="submit"]');
  const maxCharacters = 30;
  
  submitButton.addEventListener('click', (event) => {
    const trimmedValue = textField.value.trim();

    // 空検索禁止
    if (trimmedValue === '') {
      event.preventDefault();
      alert('検索ワードを入力してください。');
      // 検索ワードの文字数制限
    } else if (trimmedValue.length > maxCharacters) {
      event.preventDefault();
      alert(`検索ワードが長すぎます。`);
    }
  });
});
